import React from 'react';
import Router from 'next/router';
import * as gtag from 'lib/gtag';
import 'animate.css';
import 'index.css';
import 'react-h5-audio-player/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { Icons } from 'components/Icons/Icons';
import { format } from 'date-fns';
import Head from 'next/head';
import { ToastContainer } from 'react-toastify';
import { AppProps } from 'next/app';

Router.events.on('routeChangeComplete', (url) => gtag.pageview(url));

function App({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    window.onclick = function (e) {
      const a = e.target.closest('a');
      if (!a) return;
      if (a.href.indexOf(window.location.origin) !== 0) return;
      if (a.target === '_blank') return;
      e.preventDefault();

      const as = a.href.replace(window.location.origin, '');
      const currentLocation = window.location.pathname;

      if (as === '/calendar/entry' && currentLocation.indexOf('/module') === 0) {
        Router.push({ pathname: '/calendar/entry', query: { date: format(new Date(), 'yyyy-MM-dd'), returnhref: currentLocation } });
        return;
      }

      if (a.classList.contains('NextLink')) return;

      let href = as;
      if (href.match(/module\/.*\/.*/)) href = '/module/[stepUrl]/[pageUrl]';

      Router.push(href, as);
    };

    Router.events.on('routeChangeComplete', () => {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    });
  }, []);

  return (
    <>
      <Head>
        {/* Kanzi tool */}
        <script id="kanzi" data-defaultlanguage="en" data-apikey={process.env.REACT_APP_KANZI_APP_ID} src="https://plugin.kanzi.io/lib/kanzi.js"></script>
      </Head>
      <Component {...pageProps} />
      <Icons />
      <ToastContainer />
    </>
  );
}

export default App;
